import novelaTheme from '@narative/gatsby-theme-novela/src/gatsby-plugin-theme-ui';

export default {
  ...novelaTheme,
  initialColorMode: `light`,
  colors: {
    ...novelaTheme.colors,
    primary: '#161c2d',
    secondary: '#73737D',
    accent: '#6166DC',
    grey: '#73737D',
    background: '#fff',
  },
  fonts: {
    ...novelaTheme.fonts,
    sansSerif: "HKGroteskPro", ...novelaTheme.fonts.sansSerif,
  }
};